/**
* Template Name: UpConstruction
* Template URL: https://bootstrapmade.com/upconstruction-bootstrap-construction-website-template/
* Updated: Aug 07 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Roboto", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #364d59; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #52565e; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #feb900; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: rgba(
    255,
    255,
    255,
    0.55
  ); /* The default color of the main navmenu links */
  --nav-hover-color: #ffffff; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #feb900; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */
a {
  text-decoration: none;
}
.light-background {
  --background-color: #f4f7f6;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: #0091ff;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, #0091ff, transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #0091ff;
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  --default-color: #ffffff;
  --heading-color: #ffffff;
  color: var(--default-color);
  background-color: var(--background-color);
}


.logo {
  max-height: 150px;
  margin-right: 8px;

}
@media screen and (max-width: 768px) {
  .logo {
    max-height: 100px;
    margin-right: 8px;
    display: flex;
    justify-content: flex-end;
  
  }
}
.header a {
  text-decoration: none;
}

.header .logo h1 {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .logo span {
  font-size: 24px;
  padding-left: 1px;
  font-family: var(--heading-font);
  color: var(--color-primary);
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Global Header on Scroll
------------------------------*/
.scrolled .header {
  --background-color: rgba(255, 255, 255, 0.95);
  --heading-color: #3c3c3c;
  --nav-color: #3c3c3c;
  --nav-hover-color: #3c3c3c;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu > ul > li {
    white-space: nowrap;
    padding: 15px 14px;
  }

  .navmenu > ul > li:last-child {
    padding-right: 0;
  }

  .navmenu a,
  .navmenu a:focus {
    color: black;
    font-size: 14px;
    padding: 0 2px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-transform: uppercase;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu > ul > li > a:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #0091ff;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navmenu a:hover:before,
  .navmenu li:hover > a:before,
  .navmenu .active:before {
    visibility: visible;
    width: 100%;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: black;
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: #0091ff;
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: black;
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: fixed;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9999;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, #0091ff, transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: #0091ff;
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: #0091ff;
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: #0091ff;
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: black;

    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu  ul {
    display: flex;
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  background: url("./assets/img/carruselMobile-4.png") top center no-repeat;
  background-size: cover;
  font-size: 14px;
  position: relative;
  margin-top: 2em;
}

.footer .container {
  position: relative;
}

.footer:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 20%);
  position: absolute;
  inset: 0;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #0091ff;
  border-color: #0091ff;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;

}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;

}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;

}

.footer .footer-links ul li:first-child {
  padding-top: 0;

}

.footer .footer-links ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  display: inline-block;
  line-height: 1;
  text-decoration: none;

}

.footer .footer-links ul a:hover {
  color: #0091ff;
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 8px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-color: #0091ff transparent #0091ff transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: #0091ff;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--default-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, #0091ff, transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 300px 0 60px 0;
  text-align: center;
  position: relative;
  background-image: url("./assets/img/carruselMobile-1.png");
}

.page-title:before {
  content: "";
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 40%
  );
  position: absolute;
  inset: 0;
}

.page-title h1 {
  font-size: 56px;
  font-weight: 500;
  margin-bottom: 10px;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.page-title .breadcrumbs ol li + li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li + li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 92px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 76px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
}
@media (max-width: 768px) {
  .section-title h2 {
    width: 100%;
   
  }
}
.section-title h2:before,
.section-title h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: #0091ff;
  display: inline-block;
}

.section-title h2:before {
  margin: 0 15px 10px 0;
}

.section-title h2:after {
  margin: 0 0 10px 15px;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 50vh;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 8em;
}

.hero .info {
  position: relative;
  inset: 0;
  z-index: 3;
  padding: 140px 0 60px 0;
}

@media (max-width: 768px), (max-height: 480px) {
  .hero .info {
    padding: 100px 50px 60px 50px;
  }
}

.hero .info h2 {
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 56px;
  font-weight: 700;
  position: relative;
}

.hero .info h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 4px;
  background: #0091ff;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 768px) {
  .hero .info h2 {
    font-size: 36px;
  }
}

.hero .info p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 18px;
}

.hero .info .btn-get-started {
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #0091ff;
}

.hero .info .btn-get-started:hover {
  background: #0091ff;
}

.hero .carousel {
  inset: 0;
  position: absolute;
  overflow: hidden;
}
.hero .carousel-item {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  transition-duration: 0.4s;
  width: 100%;
}

.hero .carousel-item img {
  position: absolute;
  inset: 0;
  display: block;
  width: 50%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  margin: auto;
  width: 100%;
  object-fit: cover;

}
.carousel-item-mobile{
  display: none;
}
@media screen and (max-width: 768px ) {

  .carousel-item {
    display: none;
  }
  .hero .carousel-item img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    margin: auto;
  }

  .hero {
    width: 100%;
    min-height: 50vh;
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 3em;
  }
}

.hero .carousel-item::before {
  content: "";
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 80%
  );
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .carousel-control-prev {
  justify-content: start;
 
}

@media (min-width: 640px) {
  .hero .carousel-control-prev {
    padding-left: 15px;
  }
}

.hero .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .hero .carousel-control-next {
    padding-right: 15px;
  }
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: color-mix(in srgb, var(--default-color), transparent 80%);
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  z-index: 3;
  transition: 0.3s;
}

.hero .carousel-control-prev:focus,
.hero .carousel-control-next:focus {
  opacity: 0.5;
}

.hero .carousel-control-prev:hover,
.hero .carousel-control-next:hover {
  opacity: 0.9;
}

/*--------------------------------------------------------------
# Get Started Section
--------------------------------------------------------------*/
.get-started .content {
  padding: 30px 0;
}

.get-started .content h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
}

.get-started .content h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 4px;
  background: #0091ff;
  left: 0;
  bottom: 0;
}

.get-started .content p {
  font-size: 14px;
}

.get-started .php-email-form {
  background: color-mix(in srgb, var(--default-color), transparent 97%);
  padding: 30px;
  height: 100%;
}

@media (max-width: 575px) {
  .get-started .php-email-form {
    padding: 20px;
  }
}

.get-started .php-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-started .php-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-started .php-email-form input[type="text"],
.get-started .php-email-form input[type="email"],
.get-started .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 20%
  );
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.get-started .php-email-form input[type="text"]:focus,
.get-started .php-email-form input[type="email"]:focus,
.get-started .php-email-form textarea:focus {
  border-color: #0091ff;
}

.get-started .php-email-form input[type="text"]::placeholder,
.get-started .php-email-form input[type="email"]::placeholder,
.get-started .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.get-started .php-email-form button[type="submit"] {
  color: var(--contrast-color);
  background: #0091ff;
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 4px;
}

.get-started .php-email-form button[type="submit"]:hover {
  background: color-mix(in srgb, #0091ff, transparent 20%);
}

/*--------------------------------------------------------------
# Constructions Section
--------------------------------------------------------------*/
.constructions .card-item {
  background-color: var(--surface-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  position: relative;
  border-radius: 0;
}

.constructions .card-item .card-bg {
  min-height: 300px;
  position: relative;
}

.constructions .card-item .card-bg img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.constructions .card-item .card-body {
  padding: 30px;
}

.constructions .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}

.constructions .card-item p {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin: 0;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  background-color: var(--surface-color);
  padding: 40px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.services .service-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-bottom: 50px;
}

.services .service-item .icon i {
  color: var(--heading-color);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: color-mix(in srgb, var(--heading-color), transparent 95%);
  border-radius: 50px;
  z-index: 1;
  top: 10px;
  right: -20px;
  transition: 0.3s;
}

.services .service-item h3 {
  color: color-mix(in srgb, var(--heading-color), transparent 20%);
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  border-bottom: 4px solid
    color-mix(in srgb, var(--heading-color), transparent 90%);
  transition: 0.3s;
}

.services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.services .service-card {
  background-color: var(--surface-color);
  padding: 40px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.services .service-card .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-bottom: 50px;
}

.services .service-card .icon i {
  color: var(--heading-color);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.services .service-card .icon:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: color-mix(in srgb, var(--heading-color), transparent 95%);
  border-radius: 50px;
  z-index: 1;
  top: 10px;
  right: -20px;
  transition: 0.3s;
}

.services .service-card h3 {
  color: color-mix(in srgb, var(--heading-color), transparent 20%);
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  transition: 0.3s;
}

.services .service-card p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-card .readmore {
  margin-top: 15px;
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

/* .services .service-item:hover .icon i {
  color: var(--heading-color);
}

.services .service-item:hover .icon:before {
  background: #0091ff;
}

.services .service-item:hover h3 {
  border-color: #0091ff;
  color: var(--heading-color);
}

.services .service-item:hover .readmore {
  color: #0091ff;
} */

/*--------------------------------------------------------------
# Alt Services Section
--------------------------------------------------------------*/
.alt-services .features-image {
  position: relative;
  min-height: 400px;
}

.alt-services .features-image img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.alt-services h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.alt-services h3:after {
  content: "";
  background: #0091ff;
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  left: 0;
  bottom: 0;
}

.alt-services .icon-box {
  margin-top: 50px;
}

.alt-services .icon-box i {
  color: #0091ff;
  background-color: var(--surface-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  font-size: 28px;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  line-height: 0;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.alt-services .icon-box:hover i {
  background-color: #0091ff;
  color: var(--contrast-color);
}

.alt-services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.alt-services .icon-box h4 a {
  color: var(--heading-color);
  transition: 0.3s;
}

.alt-services .icon-box h4 a:hover {
  color: #0091ff;
}

.alt-services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  color: var(--heading-color);
  padding: 15px 0;
  transition: 0.3s;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 575px) {
  .features .nav-link h4 {
    font-size: 16px;
  }
}

.features .nav-link:hover {
  color: #0091ff;
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.features .nav-link.active {
  background-color: var(--background-color);
  color: #0091ff;
  border-color: #0091ff;
}



/* detalles */

.features .nav-link2{
  color: var(--heading-color);
  padding: 15px 0;
  transition: 0.3s;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
  }

.features .nav-link2 i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link2 h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 575px) {
  .features .nav-link2 h4 {
    font-size: 16px;
  }
}

.features .nav-link2:hover {
  color: #0091ff;
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.features .nav-link2.active {
  background-color: var(--background-color);
  color: #0091ff;
  border-color: #0091ff;
}

.nav-link2 {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

 .nav-link2.active {
  background-color: var(--background-color);
  color: #0091ff;
  border-color: #0091ff;
}




.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  color: var(--heading-color);
  font-weight: 700;
  font-size: 32px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.features .tab-pane h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: #0091ff;
  left: 0;
  bottom: 0;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-top: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #0091ff;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Projects Section
--------------------------------------------------------------*/
.projects .portfolio-filters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.projects .portfolio-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.projects .portfolio-filters li:hover,
.projects .portfolio-filters li.filter-active {
  color: #0091ff;
}

.projects .portfolio-filters li:first-child {
  margin-left: 0;
}

.projects .portfolio-filters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .projects .portfolio-filters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.projects .portfolio-content {
  position: relative;
  overflow: hidden;
}

.projects .portfolio-content img {
  transition: 0.3s;
}

.projects .portfolio-content .portfolio-info {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
}

.projects .portfolio-content .portfolio-info h4 {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  background-color: #0091ff;
}

.projects .portfolio-content .portfolio-info p {
  position: absolute;
  bottom: 10px;
  text-align: center;
  display: inline-block;
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}

.projects .portfolio-content .portfolio-info .preview-link,
.projects .portfolio-content .portfolio-info .details-link {
  position: absolute;
  left: calc(50% - 40px);
  font-size: 26px;
  top: calc(50% - 14px);
  color: #fff;
  transition: 0.3s;
  line-height: 1.2;
}

.projects .portfolio-content .portfolio-info .preview-link:hover,
.projects .portfolio-content .portfolio-info .details-link:hover {
  color: #0091ff;
}

.projects .portfolio-content .portfolio-info .details-link {
  left: 50%;
  font-size: 34px;
  line-height: 0;
}

.projects .portfolio-content:hover .portfolio-info {
  opacity: 1;
}

.projects .portfolio-content:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  position: relative;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid var(--background-color);
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, #0091ff, transparent 60%);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--background-color);
  opacity: 1;
  border: 1px solid #0091ff;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0091ff;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Recent Blog Posts Section
--------------------------------------------------------------*/
.recent-blog-posts .post-item {
  background: var(--surface-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.recent-blog-posts .post-item .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-item .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #0091ff;
  color: var(--contrast-color);
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.recent-blog-posts .post-item .post-content {
  padding: 30px;
}

.recent-blog-posts .post-item .post-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.recent-blog-posts .post-item .meta i {
  font-size: 16px;
  color: #0091ff;
}

.recent-blog-posts .post-item .meta span {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.recent-blog-posts .post-item hr {
  color: color-mix(in srgb, var(--default-color), transparent 80%);
  margin: 20px 0;
}

.recent-blog-posts .post-item .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.recent-blog-posts .post-item .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.recent-blog-posts .post-item:hover .post-title,
.recent-blog-posts .post-item:hover .readmore {
  color: #0091ff;
}

.recent-blog-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .inner-title {
  font-size: 2.75rem;
  font-weight: 700;
  margin: 30px 0;
}

@media (min-width: 991px) {
  .about .inner-title {
    max-width: 65%;
    margin: 0 0 80px 0;
  }
}

.about .our-story {
  background-color: color-mix(in srgb, var(--default-color), transparent 96%);
}

@media (min-width: 991px) {
  .about .our-story {
    padding-right: 35%;
  }
}

.about .our-story h4 {
  text-transform: uppercase;
  font-size: 1.1rem;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.about .our-story h3 {
  font-size: 2.25rem;
  font-weight: 700;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.about .our-story p:last-child {
  margin-bottom: 0;
}

.about ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.about ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.about ul i {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  line-height: 1.2;
  color: #0091ff;
}

.about .watch-video i {
  font-size: 2rem;
  transition: 0.3s;
  color: #0091ff;
}

.about .watch-video a {
  font-weight: 600;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin-left: 8px;
  transition: 0.3s;
}

.about .watch-video:hover a {
  color: #0091ff;
}

.about .about-img {
  min-height: 400px;
  position: relative;
}

@media (min-width: 992px) {
  .about .about-img {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 600px;
  }
}

.about .about-img img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter .stats-item {
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.stats-counter .stats-item i {
  color: #0091ff;
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
}

.stats-counter .stats-item span {
  color: var(--heading-color);
  font-size: 36px;
  display: block;
  font-weight: 600;
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 16px;
}

/*--------------------------------------------------------------
# Alt Services 2 Section
--------------------------------------------------------------*/
.alt-services-2 .features-image {
  position: relative;
  min-height: 400px;
}

.alt-services-2 .features-image img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.alt-services-2 h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.alt-services-2 .icon-box {
  margin-top: 30px;
}

.alt-services-2 .icon-box i {
  color: #0091ff;
  margin-right: 15px;
  font-size: 24px;
  line-height: 1.2;
}

.alt-services-2 .icon-box h4 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.alt-services-2 .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .member {
  position: relative;
}

.team .member .member-img {
  margin: 0 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1024px) {
  .team .member .member-img {
    margin: 0 60px;
  }
}

.team .member .member-img img {
  position: relative;
  z-index: 1;
}

.team .member .member-img .social {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding-bottom: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.team .member .member-img .social a {
  transition: 0.3s;
  color: var(--contrast-color);
  font-size: 20px;
  margin: 0 8px;
}

.team .member .member-img .social a:hover {
  color: #0091ff;
}

.team .member .member-info {
  margin-top: 30px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 6px;
  font-size: 18px;
}

.team .member .member-info span {
  font-style: italic;
  display: block;
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 10px;
}

.team .member .member-info p {
  margin-bottom: 0;
  font-size: 14px;
}

.team .member:hover .member-img .social {
  padding-bottom: 0;
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Features Cards Section
--------------------------------------------------------------*/
.features-cards h3 {
  font-size: 20px;
  font-weight: 700;
}

.features-cards p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.features-cards ul li {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.features-cards ul li i {
  font-size: 16px;
  color: #0091ff;
  margin-right: 6px;
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  background-color: var(--surface-color);
  padding: 10px 30px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid
    color-mix(in srgb, var(--default-color), transparent 70%);
  margin: 20px 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.service-details .services-list a.active {
  color: var(--heading-color);
  font-weight: 700;
  border-color: #0091ff;
}

.service-details .services-list a:hover {
  border-color: #0091ff;
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: #0091ff;
}

/*--------------------------------------------------------------
# Project Details Section
--------------------------------------------------------------*/
.project-details .portfolio-details-slider img {
  width: 100%;
}

.project-details .swiper-wrapper {
  height: auto;
}

.project-details .swiper-button-prev,
.project-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.project-details .swiper-button-prev:after,
.project-details .swiper-button-next:after {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.project-details .swiper-button-prev:hover:after,
.project-details .swiper-button-next:hover:after {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 575px) {
  .project-details .swiper-button-prev,
  .project-details .swiper-button-next {
    display: none;
  }
}

.project-details .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.project-details .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.project-details .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0091ff;
}

.project-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.project-details .portfolio-info h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #0091ff;
  left: 0;
  bottom: 0;
}

.project-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.project-details .portfolio-info ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.project-details .portfolio-info ul strong {
  text-transform: uppercase;
  font-weight: 400;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.project-details .portfolio-info .btn-visit {
  padding: 8px 40px;
  background: #0091ff;
  color: var(--contrast-color);
  border-radius: 50px;
  transition: 0.3s;
}

.project-details .portfolio-info .btn-visit:hover {
  background: color-mix(in srgb, #0091ff, transparent 20%);
}

.project-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.project-details .portfolio-description p {
  padding: 0;
}

.project-details .portfolio-description .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: color-mix(in srgb, var(--default-color), transparent 97%);
  margin-bottom: 50px;
}

.project-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid var(--background-color);
  float: left;
  margin: 0 10px 0 0;
}

.project-details .portfolio-description .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 5px 0;
  padding-top: 20px;
}

.project-details .portfolio-description .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left,
.project-details .portfolio-description .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, #0091ff, transparent 50%);
  font-size: 26px;
  line-height: 0;
}

.project-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.project-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.project-details .portfolio-description .testimonial-item p {
  font-style: italic;
  margin: 0 0 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Blog Posts Section
--------------------------------------------------------------*/
.blog-posts article {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.blog-posts .post-img img {
  transition: 0.5s;
}

.blog-posts .post-date {
  background-color: #0091ff;
  color: var(--contrast-color);
  position: absolute;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.blog-posts .post-content {
  padding: 30px;
}

.blog-posts .post-title {
  font-size: 20px;
  color: var(--heading-color);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.blog-posts .meta i {
  font-size: 16px;
  color: #0091ff;
}

.blog-posts .meta span {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-posts p {
  margin-top: 20px;
}

.blog-posts hr {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-bottom: 15px;
}

.blog-posts .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: color-mix(in srgb, var(--heading-color), transparent 20%);
}

.blog-posts .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.blog-posts article:hover .post-title,
.blog-posts article:hover .readmore {
  color: #0091ff;
}

.blog-posts article:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Blog Pagination Section
--------------------------------------------------------------*/
.blog-pagination {
  padding-top: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog-pagination li a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-pagination li a.active,
.blog-pagination li a:hover {
  background: #0091ff;
  color: var(--contrast-color);
}

.blog-pagination li a.active a,
.blog-pagination li a:hover a {
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Blog Details Section
--------------------------------------------------------------*/
.blog-details {
  padding-bottom: 30px;
}

.blog-details .article {
  background-color: var(--surface-color);
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #0091ff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: #0091ff;
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li + li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Blog Author Section
--------------------------------------------------------------*/
.blog-author {
  padding: 10px 0 40px 0;
}

.blog-author .author-container {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog-author h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog-author .social-links a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-right: 5px;
}

.blog-author p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Comments Section
--------------------------------------------------------------*/
.blog-comments {
  padding: 10px 0;
}

.blog-comments .comments-count {
  font-weight: bold;
}

.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog-comments .comment .comment-img img {
  width: 60px;
}

.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog-comments .comment h5 a {
  font-weight: bold;
  color: var(--default-color);
  transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
  color: #0091ff;
}

.blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
  padding-left: 40px;
}

/*--------------------------------------------------------------
# Comment Form Section
--------------------------------------------------------------*/
.comment-form {
  padding-top: 10px;
}

.comment-form form {
  background-color: var(--surface-color);
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.comment-form form h4 {
  font-weight: bold;
  font-size: 22px;
}

.comment-form form p {
  font-size: 14px;
}

.comment-form form input {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 10px;
}

.comment-form form input:focus {
  color: var(--default-color);
  background-color: var(--surface-color);
  box-shadow: none;
  border-color: #0091ff;
}

.comment-form form input::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form textarea {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  height: 120px;
}

.comment-form form textarea:focus {
  color: var(--default-color);
  box-shadow: none;
  border-color: #0091ff;
  background-color: var(--surface-color);
}

.comment-form form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form .form-group {
  margin-bottom: 25px;
}

.comment-form form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #0091ff;
  color: var(--contrast-color);
}

.comment-form form .btn-primary:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, #0091ff, transparent 20%);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  background-color: var(--surface-color);
  padding: 20px 0 30px 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .info-item i {
  font-size: 20px;
  color: #0091ff;
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border: 2px dotted color-mix(in srgb, #0091ff, transparent 40%);
}

.contact .info-item h3 {
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  background-color: var(--surface-color);
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: var(--surface-color);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form textarea:focus {
  border-color: #0091ff;
}

.contact .php-email-form input[type="text"]::placeholder,
.contact .php-email-form input[type="email"]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type="submit"] {
  color: var(--contrast-color);
  background: #0091ff;
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: color-mix(in srgb, #0091ff, transparent 20%);
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widgets-container {
  background-color: var(--surface-color);
  padding: 30px;
  margin: 60px 0 30px 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget-item {
  margin-bottom: 40px;
}

.widget-item:last-child {
  margin-bottom: 0;
}

.search-widget form {
  background: var(--background-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  padding: 3px 10px;
  position: relative;
  transition: 0.3s;
}

.search-widget form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
  background-color: var(--background-color);
  color: var(--default-color);
}

.search-widget form input[type="text"]:focus {
  outline: none;
}

.search-widget form button {
  background: #0091ff;
  color: var(--contrast-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.search-widget form button i {
  line-height: 0;
}

.search-widget form button:hover {
  background: color-mix(in srgb, #0091ff, transparent 20%);
}

.search-widget form:is(:focus-within) {
  border-color: #0091ff;
}

.categories-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget ul li {
  padding-bottom: 10px;
}

.categories-widget ul li:last-child {
  padding-bottom: 0;
}

.categories-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.categories-widget ul a:hover {
  color: #0091ff;
}

.categories-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.recent-posts-widget .post-item {
  display: flex;
  margin-bottom: 15px;
}

.recent-posts-widget .post-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item img {
  width: 80px;
  margin-right: 15px;
}

.recent-posts-widget .post-item h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
  color: var(--default-color);
  transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
  color: #0091ff;
}

.recent-posts-widget .post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
  margin-bottom: -10px;
}

.tags-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-widget ul li {
  display: inline-block;
}

.tags-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
  display: inline-block;
  transition: 0.3s;
}

.tags-widget ul a:hover {
  background: #0091ff;
  color: var(--contrast-color);
  border: 1px solid #0091ff;
}

.tags-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 14px;
}

.contact-whatsapp {
}
.contact-whatsapp a {
  color: #059652;
  font-size: 50px;
}

.maps {
  border: 0;
  width: 100%;
  height: 400px;
}

.form-container {
  font-family: Arial, sans-serif;
  background-size: cover;
  background-position: center;
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-width: 95%;

  font-family: Arial, sans-serif;
}
.booking-container h2 {
  text-align: center;
  margin-bottom: 20px;
}
.currency-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.currency-toggle button {
  width: 50%;
  padding: 10px;
  cursor: pointer;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.currency-toggle button.active {
  background-color: #b3d9ff;
}
.form-group {
  margin-bottom: 15px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group select,
.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="time"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.toggle-switch input {
  display: none;
}
.toggle-switch label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggle-switch .switch {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s;
}
.toggle-switch .switch::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s;
}
.toggle-switch input:checked + label .switch {
  background-color: #007bff;
}
.toggle-switch input:checked + label .switch::before {
  transform: translateX(20px);
}
.button-container {
  text-align: center;
}
.button-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-container button:hover {
  background-color: #0056b3;
}

/* App.css */
.card-body {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin-top: 2em;
  margin-bottom: 2em;
}

.transport-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin: 10px 0;
}

.card img {
  width: 100%;
  height: 200px; /* Forzar un tamaño uniforme */
  object-fit: cover; /* Asegurarse de que la imagen se recorte proporcionalmente */
  border-radius: 8px;
}

.features {
  list-style-type: none;
  text-align: left;
  padding: 0;
  flex-grow: 1;
}

.features li {
  margin: 5px 0;
}

.price {
  color: green;
  font-size: 20px;
  font-weight: bold;
}

.old-price {
  color: red;
  text-decoration: line-through;
  font-size: 14px;
}

.button-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-container button:hover {
  background-color: #0056b3;
}



/* Estilos básicos del formulario */
.flight-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  padding-bottom: 5em;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 8px;
}

.flight-form div {
  display: flex;
  flex-direction: column;
}

.flight-form label {
  margin-bottom: 8px;
  font-weight: bold;
}

.flight-form input,
.flight-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.flight-form textarea {
  resize: vertical;
  min-height: 100px;
}

.full-width {
  grid-column: 1 / -1;
}

.flight-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.flight-form button:hover {
  background-color: #0056b3;
}
.FormPayment-container {
  margin-top: 2em;
}
/* Para pantallas pequeñas */
@media (max-width: 600px) {
  .flight-form {
    grid-template-columns: 1fr;
  }
}

.title-client {
  max-width: 900px;

  margin: auto;
}
.payment-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10em;
}

.payment-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-option img {
  width: 150px; /* Definimos un ancho fijo para las imágenes */
  height: 150px; /* Definimos una altura fija para las imágenes */
  object-fit: contain; /* Mantenemos el aspecto de la imagen */
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.payment-option img.selected {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.payment-option button {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.payment-option button.selected {
  background-color: #007bff;
  color: white;
}

.payment-option button:hover {
  background-color: #0056b3;
}

.full-width {
  grid-column: 1 / -1;
  margin-top: 20px;
  text-align: center;
}

/* Para pantallas pequeñas */
@media (max-width: 600px) {
  .payment-methods {
    flex-direction: column;
    gap: 2em;
  }

  .payment-option {
    margin-bottom: 20px;
  }
}

.mapsvg-region {
  cursor: pointer;
  stroke: #fff;
  stroke-width: 1px;
  fill: grey;
  z-index: -2;
}

.mapsvg-region:hover {
  cursor: pointer;
  stroke: #fff;
  stroke-width: 1px;
  fill: #0d6efd;
}

.map-container {
}

.icon-color {
  color: white;
}

.bsb-timeline-4 {
  padding: 3rem 0;
  background-color: #f8f9fa; /* Fondo claro */
}

.bsb-timeline-4 h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #343a40;
  font-weight: 700;
  margin-bottom: 2rem;
}

.linea-tiempo {
  position: relative;
  padding: 1rem 0;
  overflow-y: auto;
  max-height: 80vh;
}

.timeline {
  list-style: none;
  padding: 0;
}

.timeline-item {
  position: relative;
  margin: 2rem 0; /* Espaciado vertical entre elementos */
}

/* Línea que conecta los años */
.timeline-item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px; /* Grosor de la línea */
  background: #007bff; /* Color de la línea */
  transform: translateX(-50%); /* Centrar la línea */
  z-index: 0; /* Asegura que la línea esté detrás de los elementos */
}

.timeline-meta {
  position: absolute;
  width: 80px; /* Ancho del año */
  left: calc(50% - 40px); /* Centrado */
  text-align: center;
  font-weight: bold;
  color: #fff; /* Color del texto */
  background: #007bff; /* Color de fondo */
  border-radius: 0.25rem; /* Bordes redondeados */
  z-index: 1; /* Asegura que el texto esté sobre la línea */
}

.timeline-meta span {
  display: block;
  padding: 0.5rem;
}

.timeline-body {
  display: flex;
  flex-direction: row;
}

.timeline-content {
  position: relative;
  background: #fff; /* Fondo blanco para las tarjetas */
  border-radius: 0.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin-left: 2rem; /* Espaciado a la izquierda */
}

.timeline-item.right .timeline-content {
  margin-left: 0; /* No margen a la izquierda para elementos a la derecha */
  margin-right: 2rem; /* Espaciado a la derecha */
}

.timeline-content:hover {
  transform: translateY(-5px); /* Efecto hover */
}

.card {
  border: none !; /* Sin borde para las tarjetas */
  width: 500px;
}

@media (max-width: 768px) {
  .card {
    width: 345px;
  }
}

.card-img-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-body {
  padding: 1.5rem; /* Espaciado interno de la tarjeta */
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.card-text {
  color: #6c757d; /* Color de texto secundario */
  line-height: 1.6; /* Altura de línea */
}

@media (min-width: 768px) {
  .timeline-item.left .timeline-content {
    margin-left: auto; /* Para centrar en pantallas más grandes */
  }
}

.whatsapp {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 70px;
  z-index: 9999;
  right: 10px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 25px;
  text-decoration: none;
  box-shadow: 0px 0px 10px #25d366;
  padding: 3px;
}

.whatsapp:hover {
  box-shadow: 0px 0px 50px #25d366;
}

.icon_whatsapp {
  color: white;
  margin-top: 10px;
}

.gallery-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  margin-top: 3em;
}

.img-gallery {
  width: 100%;
  max-width: 345px;
  height:345px ;
}

/* App.css */
.hover-container {
  position: relative;
  display: inline-block;
}

.card-toolip {
  position: absolute;
  top: 110px; /* Ajusta la posición de la tarjeta */
  left: 0;
  width: 200px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.page-schools {
  margin-top: 5em;
}

@media screen and (max-width: 768px) {
  .page-schools {
    margin-top: 2em;
  }
}

.responsive-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.update-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  overflow-y: auto;
  max-height: 600px;
}
.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 0.5rem;
}

input[type="text"],
select,
input[type="file"] {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}



.preview-image {
  text-align: center;
}

.preview-image img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 1rem;
}

/* Responsive */
@media (max-width: 768px) {
  .responsive-form {
    padding: 0.5rem;
  }

  .button-form {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .update-form {
    padding: 0.5rem;

  }
}
.school-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  max-width: 600px;
  width: 100%;
  transition: transform 0.3s ease;
}

.school-card:hover {
  transform: translateY(-5px);
}

.school-image img {
  width: 100%;
  height: auto;
  border-bottom: 5px solid #3f51b5;
}

.school-details {
  padding: 20px;
}

.school-details h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.school-details p {
  font-size: 16px;
  margin: 8px 0;
  color: #555;
}

.school-details p strong {
  color: #3f51b5;
}

@media screen and (max-width: 768px) {
  .school-card {
    flex-direction: column;
  }

  .school-details h1 {
    font-size: 22px;
  }

  .school-details p {
    font-size: 14px;
  }
}


/* ------------- */

.school-container {
  width: 90%;
  max-width: 1200px;
  margin: 5em auto;
  padding: 20px;
}

.school-header {
  text-align: center;
  margin-bottom: 40px;
}

.school-header h1 {
  font-size: 36px;
  color: #2b579a;
}

.school-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.info-section {
  flex: 1 1 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-section h2 {
  color: #2b579a;
  margin-bottom: 15px;
}

.map-section {
  flex: 1 1 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-section iframe {
  width: 100%;
  height: 250px;
  border-radius: 10px;
}

.school-convocations {
  margin-top: 40px;
}

.school-convocations h2 {
  color: #2b579a;
  margin-bottom: 20px;
}

.convocations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.convocation-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.convocation-card h3 {
  margin-bottom: 10px;
  color: #2b579a;
}

.pdf-link button {
  background-color: #2b579a;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.pdf-link button:hover {
  background-color: #1a3f72;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .school-info {
    flex-direction: column;
  }

  .map-section iframe {
    height: 200px;
  }

  .convocation-card {
    font-size: 14px;
  }
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none; /* Para evitar que el tooltip interfiera con el mouse */
  z-index: 1000;
}

.mobile-province {
  display: none;

}

@media screen and (max-width: 768px) {
  .mobile-province {
    display: grid;
  
  }
}
.details-page {
  margin-top: 12em;
}
.about-page {
  margin-top: 5em;
}

.info-section {
  flex: 1 1 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.btn:hover {
  background-color: #0056b3;
}

h3 {
  margin-top: 20px;
}

.record-list {
  list-style-type: none;
  padding: 0;
}

.record-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-delete:hover {
  background-color: #c82333;
}
.alumn-card{
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cardalumn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

/* PrivacyPolicy.css */
.privacy-policy-container {
  max-width: 800px;
  margin: 10em auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.privacy-policy-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #0073e6;

}

.privacy-policy-container h2 {
  font-size: 1.5em;
  margin-top: 20px;
  color: #0073e6;
}

.privacy-policy-container p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}


.main-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  font-size: 24px;
}

header p {
  font-size: 14px;
  color: #666;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.filters-section {
  grid-column: span 2;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-item label {
  font-size: 14px;
  margin-bottom: 5px;
}

.filter-item select {
  padding: 5px;
  width: 150px;
}

.reset-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.map-section {
  grid-column: span 2;
}

.statistics-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.statistics-item {
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-number {
  font-size: 24px;
  font-weight: bold;
}

.stat-label {
  font-size: 14px;
  color: #666;
}

.data-map {
  display: flex;
  transition: opacity 0.5s ease, transform 0.5s ease;
  flex-wrap: wrap;
  transform: translateY(20px); /* inicial */
  gap: 5em;
  place-items: center;
  justify-content: center;
}



.view-data {
  color: #0056b3;
  cursor: pointer;
}

.view-data:hover {
color: #007bff;
}

.data-students {
background-color: #0056b3;
color: white;
padding: 20px;
place-items: center;
border-radius: 1em;

}

.total-students {
  text-align: center;
}

.porcentaje-students {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.students-chart {
  position: relative;
  width: 150px;
  height: 150px;
}

.circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-segment {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.circle-center {
  position: absolute;
  width: 70%;
  height: 70%;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
}


.students-labels {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.students-women-info,
.students-men-info {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.container-students-doctors {
  display: flex;
  flex-wrap: wrap;
  gap: 5em;
}


.teacher-current {
  display: flex;
}



.vinculos {
  color: var(--heading-color);
  padding: 15px 0;
  transition: 0.3s;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.vinculos i {
  padding-right: 15px;
  font-size: 48px;
}

 .vinculos h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 575px) {
 .vinculos h4 {
    font-size: 16px;
  }
}

 .vinculos:hover {
  color: #0091ff;
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.vinculos.active {
  background-color: var(--background-color);
  color: #0091ff;
  border-color: red;
}


.card-pdf {
  width: 100px;
}
.vinculos {
  font-size: 22px !important;
}
.vinculos:hover {
  background-color: #0091ff;
  color: white !important;
}
.bg-number-history {
  background-color: #2b579a;
}
.number-history {
font-size: 30px;
color: white;
}


.table-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.custom-table {
  width: 50%;
  border-collapse: collapse;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: center;
}

.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}


.galeria-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}



/* Tabla.css */
.tabla-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #1c3b8b; /* Azul para el título */

}

.tabla-periodo {
  font-weight: bold;
  font-size: 1.2em;
  color: #1c3b8b; /* Azul para el título */
  margin-bottom: 0;
  border: 0px 1px 0px  solid #ffcc00; /* Bordes amarillos */
  border-left: 1px solid #ffcc00; /* Bordes amarillos */
  border-right: 1px solid #ffcc00; /* Bordes amarillos */
  border-top: 1px solid #ffcc00; /* Bordes amarillos */
 width: 80%;
 text-align: center;
}

.tabla {
  border-collapse: collapse;
  width: 80%;
  font-family: Arial, sans-serif;
  text-align: center;
  color: #1c3b8b; /* Azul para el título */
  border: 1px solid #ffcc00; /* Bordes amarillos */

}

.tabla  th,
td {
  border: 1px solid #ffcc00; /* Bordes amarillos */
  padding: 8px;
  font-size: 1em;
}

.tabla  th {
  background-color: #f3f4f6; /* Fondo gris claro */
  color: #000;
  border: 1px solid #ffcc00; /* Bordes amarillos */
  color: #1c3b8b; /* Azul para el título */

}

.tabla  tbody tr {
  background-color: #ffffff; /* Fondo blanco */
  border: 1px solid #ffcc00; /* Bordes amarillos */
  
}

.tabla tbody tr td {
  border: 1px solid #ffcc00; /* Bordes amarillos */
  font-size: 0.9em;
}

.tabla th:first-child,
td:first-child {
  width: 50px; /* Ajustar ancho de la columna 'N°' */
  border: 1px solid #ffcc00; /* Bordes amarillos */

}

.tabla th:last-child,
td:last-child {
  width: 80px; /* Ajustar ancho de la columna 'Edad' */
  border: 1px solid #ffcc00; /* Bordes amarillos */

}


/* doctor */
.doctor-tabla-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #000; /* Azul para el título */

}

.doctor-tabla-periodo {
  font-weight: bold;
  font-size: 1.2em;
  color: #000; /* Azul para el título */
  margin-bottom: 0;
  border: 0px 1px 0px  solid #000; /* Bordes amarillos */
  border-left: 1px solid #000; /* Bordes amarillos */
  border-right: 1px solid #000; /* Bordes amarillos */
  border-top: 1px solid #000; /* Bordes amarillos */
 width: 80%;
 text-align: center;
 background-color: #ffcc00;
}

.doctor-tabla {
  border-collapse: collapse;
  width: 80%;
  font-family: Arial, sans-serif;
  text-align: center;
  color: #000; /* Azul para el título */
  border: 1px solid #000; /* Bordes amarillos */

}

.doctor-tabla  th,
td {
  border: 1px solid #000; /* Bordes amarillos */
  padding: 8px;
  font-size: 1em;
  
}

.doctor-tabla  th {
  background-color: #f3f4f6; /* Fondo gris claro */
  color: #000;
  border: 1px solid #000; /* Bordes amarillos */
  color: #000; /* Azul para el título */

  background-color: #ffcc00;
}

.doctor-tabla  tbody tr {
  background-color: #ffffff; /* Fondo blanco */
  border: 1px solid #000; /* Bordes amarillos */
  
}

.doctor-tabla tbody tr td {
  border: 1px solid #000; /* Bordes amarillos */
  font-size: 0.9em;
  
}

.doctor-tabla th:first-child,
td:first-child {
  width: 50px; /* Ajustar ancho de la columna 'N°' */
  border: 1px solid #000; /* Bordes amarillos */

}

.doctor-tabla th:last-child,
td:last-child {
  width: 80px; /* Ajustar ancho de la columna 'Edad' */
  border: 1px solid #000; /* Bordes amarillos */

}

.btnGaleria {
  cursor: pointer;
  background-color: red;
  padding: 5px;
  color: white;
  width: 20%;
  text-align: center;
}

.right-navbar{
  overflow-y: auto;
  max-height: 500px;
  position: fixed;
  width: 100%;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
}

